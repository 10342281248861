// 
// _layouts.scss
// 

body[data-layout-size="boxed"] {
    background-color: $boxed-body-bg;
    #layout-wrapper {
        background-color: $body-bg;
        max-width: $boxed-layout-width;
        margin: 0 auto;
        box-shadow: $box-shadow;
    }

    #page-topbar {
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
        margin: 0 auto;
    }

    .footer {
        margin: 0 auto;
        max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    }

    &.vertical-collpsed {
        #page-topbar {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
        .footer {
            max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
        }
    }
}


// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"]{
    #page-topbar, #layout-wrapper, .footer {
        max-width: 100%;
    }
    .container-fluid, .navbar-header {
        max-width: $boxed-layout-width;
    }
}

// Scrollable layout
body[data-layout-scrollable="true"] {
    @media (min-width: 992px) {
        #page-topbar, .vertical-menu{
            position: absolute;
        }
        .navbar-brand-box {
            position: absolute;
        }
    }
    &[data-layout="horizontal"]{
        @media (min-width: 992px) {
            #page-topbar{
                position: absolute;
            }

            .navbar-brand-box {
                position: relative;
            }
        }
    }
}


body[data-layout-mode="dark"] {
    &[data-layout-size="boxed"] {
        background-color: lighten($gray-dark-200, 4%);
        #layout-wrapper {
            background-color: darken($gray-dark-200, 2%);
        }
    }
}