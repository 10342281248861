//
// alerts.scss
//

.alert-top-border {
    background-color: $card-bg;
    border-color: $light;
    border-top: 2px solid $light;
}

.alert-outline{
    background-color: $white;
}

.alert-light{
    color: $text-muted;
}

@each $color,
$value in $theme-colors {
    .alert-top-border{
        &.alert-#{$color} {
            border-top-color: $value;
            color: $value;
        }
    }

    .alert-outline{
        &.alert-#{$color} {
            border: 1px solid rgba($value, .8);
            color: $value;
        }
    }
}

.alert-top-border, .alert-outline{
    &.alert-light{
        color: $text-muted;
    }
}

body[data-layout-mode="dark"] {
      // Alert
    .alert{
        .btn-close{
            background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
        }
    }
    .alert-top-border{
        background-color: $gray-dark-200;
        border-top: 2px solid $gray-dark-300;
        border-color: $gray-dark-300;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
    }
    .alert-top-border{
        background-color: $gray-dark-200;
        border-top: 2px solid $gray-dark-300;
        border-color: $gray-dark-300;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
    }

    .alert-outline{
        background-color: $gray-dark-200;
        .btn-close{
            background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
        }
        &.alert-light{
            color: $light;
        }
    }

    @each $color,
    $value in $theme-colors {
        .alert-top-border{
            &.alert-#{$color} {
                border-top-color: $value;
                color: $value;
            }
        }
    }

    .alert-top-border{
        &.alert-dark{
            border-top-color: $gray-dark-300;
            border-color: $gray-dark-300;
            color: $light;
        }
    }

    .alert-outline{
        &.alert-dark{
            border-color: $gray-dark-300;
            color: $light;
        }
    }
}